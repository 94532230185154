import { Tab as TabType } from '@yleisradio/areena-types';
import { Container } from 'components/Container';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { HighlightCard } from 'components/HighlightCard';
import { List } from 'components/List';
import { getImagePosition } from 'components/HighlightCard/getImagePosition';
import { Notification } from 'components/Notifications/Notification';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { useAnalyticsForTab } from 'hooks/analytics';
import { useTranslation } from 'hooks/useTranslation';
import React from 'react';
import logger from 'services/logger';
import { createHighlightCardKey } from 'utils/content';

interface TabContentProps {
  tab: TabType;
}

export const TabContent: React.FunctionComponent<TabContentProps> = ({
  tab: { analytics, content: contentArray = [] },
}) => {
  useAnalyticsForTab(analytics);
  const t = useTranslation();

  const errorFallback = (
    <Container>
      <Notification
        notification={{ uiMessage: t('listLoadingFailed') }}
        notificationStyle="error"
        helpText={t('notificationHelpTextGeneric')}
      />
    </Container>
  );

  return (
    <AnalyticsContextProvider context={analytics?.context?.comscore}>
      <div data-testid="tab-content">
        {contentArray.map((content, index) => {
          switch (content.type) {
            case 'highlightCard': {
              const key = createHighlightCardKey(content, index);

              return (
                <ErrorBoundary key={key}>
                  <HighlightCard
                    card={content}
                    cardKey={key}
                    imagePosition={getImagePosition(content, contentArray)}
                    uiPositionindex={index}
                  />
                </ErrorBoundary>
              );
            }

            case 'list': {
              const key = content.source.uri;

              return (
                <ErrorBoundary fallback={errorFallback} key={key}>
                  <List index={index} list={content} listKey={key} />
                </ErrorBoundary>
              );
            }

            default: {
              logger.warn(`Content type "${content.type}" is not supported`);
              return null;
            }
          }
        })}
      </div>
    </AnalyticsContextProvider>
  );
};
