import React, { useEffect, useId, useRef } from 'react';
import { Button } from 'components/Button';
import ChevronDown from 'assets/chevronDown.svg';
import ChevronUp from 'assets/chevronUp.svg';
import { useTranslation } from 'hooks/useTranslation';
import styles from './ShowMore.module.scss';
import classNames from 'classnames';

type Props = {
  showLessChildren: React.ReactNode;
  showMoreChildren: React.ReactNode;
  isShowingMore: boolean;
  toggleShowMore: () => void;
};

export const ShowMore: React.FunctionComponent<Props> = ({
  isShowingMore,
  showLessChildren,
  showMoreChildren,
  toggleShowMore,
}) => {
  const contentId = useId();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isShowingMore && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isShowingMore]);

  const t = useTranslation();
  const showMoreButtonText = isShowingMore
    ? t('hideShowMoreInformation')
    : t('showMoreInformation');

  return (
    <div>
      <div id={contentId} ref={contentRef} tabIndex={-1}>
        {isShowingMore ? showMoreChildren : showLessChildren}
      </div>
      <div className={classNames(isShowingMore && styles.buttonWhenOpen)}>
        <Button
          onClick={toggleShowMore}
          text={showMoreButtonText}
          variant="text"
          padding="vertical"
          size="xs"
          iconRight={isShowingMore ? ChevronUp : ChevronDown}
          aria-expanded={isShowingMore}
          aria-controls={contentId}
        />
      </div>
    </div>
  );
};
