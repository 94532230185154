import { Card } from '@yleisradio/areena-types';
import React from 'react';
import styles from './UpcomingProgram.module.scss';
import { Time } from 'components/Time';
import { findLabel } from 'utils/card';
import { Link } from 'components/Link';

type Props = {
  card: Card;
};

export const UpcomingProgram: React.FC<Props> = ({ card }) => {
  const contents = (
    <>
      <b className={styles.time}>
        <Time date={findLabel(card.labels, 'broadcastStartDate')?.raw} />
      </b>{' '}
      <span>{card.title}</span>
    </>
  );

  return card.pointer ? (
    <Link className={styles.root} pointer={card.pointer}>
      {contents}
    </Link>
  ) : (
    <div className={styles.root}>{contents}</div>
  );
};
